import axios from 'axios'
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

export default function contatoForm() {
	const showToastify = (message, opts) => {
		Toastify({
			duration: 3000,
			position: "center",
			style: { background: "rgba(193, 255, 129)", fontSize: "18px", color: "rgba(45, 71, 120, 1)" },
			text: message,
			...opts
		}).showToast();
	}

	const btnFormContato = document.querySelector('.btn-contact')
	const formContato = document.querySelector('#enter-in-contact')

	btnFormContato && btnFormContato.addEventListener('click', function (event) {
		event.preventDefault();
		disabledBtn()
		const form_data = {
			nome: document.querySelector('#enter-in-contact input[name=nome]').value,
			email: document.querySelector('#enter-in-contact input[name=email]').value,
			telefone: document.querySelector('#enter-in-contact input[name=telefone]').value,
			mensagem: document.querySelector('#enter-in-contact textarea[name=mensagem]').value,
		}

		const url = "/fale-conosco/"
		axios.post(url, form_data)
			.then((response) => showMessage(response))
			.catch((response) => showMessage(response));
	});

	function showMessage(response) {
		const isSuccess = response.data && response.data.message;
		// const message = response.data ? response.data.message : response.message

		const message = isSuccess ? 'Message sent successfully! Thank you!' : 'Oops, something went wrong! Try again!'

		const backgroundColor = isSuccess ? "rgba(193, 255, 129)" : "rgba(233, 44, 44)";
		const textColor = isSuccess ? "rgba(45, 71, 120, 1)" : "white";

		showToastify(message, {
			style: {
				background: backgroundColor,
				color: textColor,
				fontSize: "18px",
				borderRadius: "8px"
			},
		});
		formContato.reset()
		setTimeout(enabledBtn, 1000)
	}

	function disabledBtn() {
		btnFormContato.disabled = true
		btnFormContato.classList.add("disabled")
	}

	function enabledBtn() {
		btnFormContato.disabled = false
		btnFormContato.classList.remove("disabled")
	}
}