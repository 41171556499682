import { find } from "utils/dom"

export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return

	const inputs = Home.querySelectorAll('.input-contato')
	const btnContact = document.querySelector('.btn-contact')

	if (inputs) {
		function checkInputs() {
			const allInputsFilled = Array.from(inputs).every(input => input.value.trim() !== '')

			if (allInputsFilled) {
				btnContact.removeAttribute('disabled')
			} else {
				btnContact.setAttribute('disabled', '')
			}
		}

		Array.from(inputs)?.forEach(input => {
			input.addEventListener('input', checkInputs)
		})

		checkInputs()
	}
}