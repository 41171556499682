export default function menu() {
	const btnMobile = document.querySelector('#btn-mobile');
	const btnOpenMenu = document.querySelector('#btn-open-menu');
	const btnCloseMenu = document.querySelector('#btn-close-menu');
	const linksMenu = document.querySelectorAll('#menu-mobile ul li');
	const htmlEl = document.querySelector('html');
	const menuMobile = document.querySelector('#menu-mobile');

	// Function to toggle the menu visibility
	function toggleMenu(event) {
		if (event.type === 'touchstart') event.preventDefault();

		if (htmlEl) {
			// Toggle button visibility
			btnOpenMenu.classList.toggle('hidden');
			btnCloseMenu.classList.toggle('hidden');

			// Toggle menu opacity and pointer events
			menuMobile.classList.toggle('opacity-100');
			menuMobile.classList.toggle('opacity-0');
			menuMobile.classList.toggle('pointer-events-auto');
			menuMobile.classList.toggle('pointer-events-none');

			// Toggle html overflow
			htmlEl.classList.toggle('overflow-hidden');
		}
	}

	// Event listener for the mobile menu button
	if (btnMobile) {
		btnMobile.addEventListener('click', toggleMenu);
	}

	// Event listeners for each menu item
	linksMenu.forEach(link => {
		link.addEventListener('click', () => {
			// Hide the menu
			menuMobile.classList.remove('opacity-100');
			menuMobile.classList.remove('pointer-events-auto');
			menuMobile.classList.add('opacity-0');
			menuMobile.classList.add('pointer-events-none');

			// Toggle button visibility
			btnOpenMenu.classList.toggle('hidden');
			btnCloseMenu.classList.toggle('hidden');

			// Restore html overflow
			htmlEl.classList.remove('overflow-hidden');
		});
	});
}
